import React from "react";
import { useMessage } from "@messageformat/react";
import { logoutCurrentUser } from "@telia/b2b-corp-login";
import { currentLocale } from "@telia/b2b-i18n";
import { corpLogin } from "@telia/b2b-rest-client";
import { LoginRequestDTO } from "@telia/b2b-rest-client/dist/corp-login";
import { LoginUrlResponseDTO } from "@telia/b2b-rest-client/dist/corp-login";
import { B2B_GENERAL_ERROR_URL, B2B_ORGANIZATION_REGISTRATION_BASE_URL } from "@telia/b2b-utils";
import { colors } from "@teliads/components/foundations";
import { TeliaCol, TeliaLink, TeliaP, TeliaRow, TeliaTextSpacing } from "@teliads/components/react";
import UserAdminSvg from "../../../../assets/graphics/user-admin.svg";
import Card from "../../Card";
import { AlertContainer, TeliaColCentered, TeliaGridFlex, TeliaRowSpaced } from "../../Layout";
import {
  HeadingWithSpacing,
  LinkAndTextContainer,
  LinkContainer,
  LogoutIcon,
  TeliaRowCentered,
} from "./styling-components";
import {
  getGa4OrganizationRegistrationParams,
  getGa4StepParams,
  trackGa4,
} from "@telia/b2b-analytics";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export function NoAccessBankId() {
  const messages = useMessage("no-access-bank-id");
  const bankIdCardMessages = useMessage("no-access-bank-id.card-bankid");
  const getMyBCardMessages = useMessage("no-access-bank-id.card-get-mybusiness");

  const { width } = useWindowDimensions();
  // TODO: Use purpur breakpoints instead, but they're broken atm. 2023-12-19
  const isMediumViewPort = width < 600;
  function logout() {
    const fallBackUrl = new URL(window.location.href);
    logoutCurrentUser().then((returnUrl) => {
      if (returnUrl) {
        window.location.assign(returnUrl);
      } else {
        window.location.assign(fallBackUrl);
      }
    });
  }
  async function switchToBankId() {
    const returnUrl = "/foretag/mybusiness/installningar/minprofil";
    const body: LoginRequestDTO = {
      targetUrl: returnUrl,
      forceMethod: "PASSWORD",
    };
    const response: LoginUrlResponseDTO = await corpLogin.LoginControllerService.initiateLoginUrl(
      body,
      currentLocale(),
      "login"
    );
    const ciamUrl = new URL(response.redirectUrl ? response.redirectUrl : B2B_GENERAL_ERROR_URL);

    window.location.assign(ciamUrl.href);
    return;
  }

  return (
    <div
      data-testid="b2b-no-access-page-root"
      id="noAccessPage"
      style={{ backgroundColor: colors.gray50 }}
    >
      <AlertContainer>
        <TeliaGridFlex>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <UserAdminSvg
                width={isMediumViewPort ? 128 : 192}
                height={isMediumViewPort ? 128 : 192}
              />
            </TeliaColCentered>
          </TeliaRowSpaced>
          <TeliaRowSpaced>
            <TeliaColCentered>
              <TeliaTextSpacing>
                <HeadingWithSpacing tag="h2" variant="title-400" data-testid="b2b-no-access-title">
                  {messages.title()}
                </HeadingWithSpacing>
              </TeliaTextSpacing>
              <TeliaP variant="preamble-100">{messages.preamble()}</TeliaP>
            </TeliaColCentered>
          </TeliaRowSpaced>
          <TeliaRowCentered>
            <TeliaCol width={12} widthLg={4}>
              <Card
                heading={bankIdCardMessages.title()}
                content={bankIdCardMessages.description()}
                buttonText={bankIdCardMessages.buttonText()}
                ctaVariant="primary"
                onButtonClick={switchToBankId}
              />
            </TeliaCol>
            <TeliaCol width={12} widthLg={4}>
              <Card
                heading={getMyBCardMessages.title()}
                content={getMyBCardMessages.description()}
                buttonText={getMyBCardMessages.buttonText()}
                ctaVariant="secondary"
                onButtonClick={() => {
                  trackGa4("account_registration_initiate", {
                    ...getGa4OrganizationRegistrationParams("no_access"),
                    ...getGa4StepParams("account_registration_initiate", 1, "create_account"),
                  });

                  window.location.assign(B2B_ORGANIZATION_REGISTRATION_BASE_URL);
                }}
              />
            </TeliaCol>
          </TeliaRowCentered>
          <TeliaRow>
            <LinkAndTextContainer>
              <TeliaP>{messages.logoutText()}</TeliaP>
              <LinkContainer onClick={logout} role="link" tabIndex={0}>
                <TeliaLink variant="standalone">
                  <LogoutIcon name="logout" slot="left" size="sm" />
                  {messages.logoutLinkText()}
                </TeliaLink>
              </LinkContainer>
            </LinkAndTextContainer>
          </TeliaRow>
        </TeliaGridFlex>
      </AlertContainer>
    </div>
  );
}
